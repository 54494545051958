import type { TaxonomyFieldAnything } from '@grasp-gg/lib-core-taxonomies';

import { fieldColumnAlias, parametersAt, self } from './utils';

export function asDataValidation(field: TaxonomyFieldAnything) {
  const validation = {
    custom: {
      formula: `=AND(
        LEN(${self()})>=${parametersAt(fieldColumnAlias(field.id), 1)},
        LEN(${self()})<=${parametersAt(fieldColumnAlias(field.id), 2)},
        AND(${/* Check there is no #VALUE! (meaning invalid character) */ ''}
          NOT(${/* We want them to NOT be in the excluded list */ ''}
            ISNUMBER(${/* 1 if char is inside, #VALUE! if not */ ''}
              FIND(${/* For each character, find if it is in the allowed list */ ''}
                MID(${/* Extract all string characters */ ''}
                  ${parametersAt(fieldColumnAlias(field.id), 3)},
                  SEQUENCE(1, LEN(${parametersAt(fieldColumnAlias(field.id), 3)}), 1, 1),${/* Generates a list of character index for each char */ ''}
                  1
                ),
                ${self()}
              )
            )
          )
        )
      )`.replace(/\s+/g, ''),
    },
  } satisfies Excel.DataValidationRule;

  if (validation.custom.formula.length > 255)
    console.error(
      `Field validation for ${field.id} will fail due to formula length. Allowed < 255 characters, got ${validation.custom.formula.length}`,
      {
        formula: validation.custom.formula,

        field,
      },
    );

  return validation;
}

export function encodeParameters(field: TaxonomyFieldAnything) {
  return [field.parameters.min, field.parameters.max, field.parameters.exclude];
}
