import type { TaxonomyFieldFixed } from '@grasp-gg/lib-core-taxonomies';

import { fieldColumnAlias, parametersAt, self } from './utils';

export function asDataValidation(field: TaxonomyFieldFixed) {
  return {
    custom: {
      formula: `=EXACT(
        ${self()},
        ${parametersAt(fieldColumnAlias(field.id), 1)}
      )`.replace(/\s+/g, ''),
    },
  } satisfies Excel.DataValidationRule;
}

export function encodeParameters(field: TaxonomyFieldFixed) {
  return [field.parameters.value];
}
