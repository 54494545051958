import { jwtDecode } from 'jwt-decode';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

type IdTokenPayload = {
  'custom:organizationId': string;
  email: string;
  sub: string;
  name: string;
  exp: number;
};

export interface User {
  name: string;
  email: string;
  userId: string;
  organizationId: string;
}

export const useAuthStore = defineStore('authentication', () => {
  const cognitoIdToken = ref<string | undefined>(undefined);

  const tokenPayload = computed(() => {
    if (!cognitoIdToken.value) return undefined;

    return jwtDecode<IdTokenPayload>(cognitoIdToken.value);
  });

  const user = computed(() => {
    if (!tokenPayload.value) return undefined;

    return {
      name: tokenPayload.value.name,
      email: tokenPayload.value.email,
      userId: `usr_${tokenPayload.value.sub}`,
      organizationId: tokenPayload.value['custom:organizationId'],
    };
  });

  const hasExpired = computed(
    () => !tokenPayload.value || Date.now() / 1_000 > tokenPayload.value.exp,
  );

  const logout = async () => {
    await OfficeRuntime.storage.removeItem('refreshToken');
    cognitoIdToken.value = undefined;
  };

  const authenticate = async (options: {
    refreshToken: string;
    accessToken?: string;
    idToken?: string;
  }) => {
    try {
      if (options.idToken) {
        cognitoIdToken.value = options.idToken;
      } else {
        const auth = await refreshAccessToken(options.refreshToken);

        cognitoIdToken.value = auth.id_token;
      }

      await OfficeRuntime.storage.setItem('refreshToken', options.refreshToken);
    } catch (error) {
      console.error('Failed to authenticate', error);
      await logout();
    }
  };

  /** Update the access token based on the stored refresh token */
  const refreshAuthentication = async () => {
    const refreshToken =
      (await OfficeRuntime.storage.getItem('refreshToken')) ?? null;

    if (refreshToken) await authenticate({ refreshToken });
  };

  /** Ensure the auth token has not expired, refresh it if required */
  const tryToAuthenticate = async () => {
    if (hasExpired.value) await refreshAuthentication();
  };

  /** Return the access token or fail if not authenticated */
  const getAccessToken = async () => {
    await tryToAuthenticate();

    if (!cognitoIdToken.value) throw new Error('Not authenticated');

    return cognitoIdToken.value;
  };

  return {
    user,
    isAuthenticated: computed(() => !hasExpired.value),
    logout,
    authenticate,
    tryToAuthenticate,
    getAccessToken,
  };
});

async function refreshAccessToken(refreshToken: string): Promise<{
  id_token: string;
  access_token: string;
  expires_in: number;
  token_type: 'Bearer';
}> {
  const requestUrl = new URL(
    `${import.meta.env.VITE_COGNITO_HOST}/oauth2/token`,
  );

  requestUrl.searchParams.append('grant_type', 'refresh_token');
  requestUrl.searchParams.append('refresh_token', refreshToken);
  requestUrl.searchParams.append(
    'client_id',
    import.meta.env.VITE_COGNITO_APP_ID,
  );

  const result = await fetch(requestUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

  return result.json();
}
