import { escape } from 'lodash';

import type { StandardField } from '../../fields';
import { containsExcludedWords } from '../get-regex-by-field/by-field-type-anything';
import getReturnText from './get-return-text';
import getValidRegexes from './get-valid-regexes';
import type { Options, ValidRegex } from './types';

export default (
  fields: StandardField[],
  text: string,
  options: Options = {},
): {
  invalidFields: number[];
  returnText: string;
  matches: string[];
  validRegexes?: ValidRegex[];
} => {
  if (!fields.length) {
    return {
      invalidFields: [],
      returnText: text,
      matches: [],
    };
  }

  let returnText = text;

  const validRegexes: ValidRegex[] = fields.reduce<ValidRegex[]>(
    getValidRegexes(fields, text, options),
    [] as ValidRegex[],
  );

  const regexp = `^${validRegexes.map(([regex]) => regex).join('')}${options.instantFail ? '$' : '$'}`;
  const result = new RegExp(regexp, 'm').exec(text);

  if (!result) {
    throw Object.assign(Error('regex doesnt match'), {
      name: 'RegexDoesntMatch',
      regexp,
      text,
    });
  }

  const [, ...rawMatches] = result;

  const invalidFields = validRegexes
    .map(([, valid], index) => {
      if (
        !valid ||
        (fields[index].type === 'anything' &&
          containsExcludedWords(fields[index], rawMatches[index]))
      ) {
        return index;
      }

      return null;
    })
    .filter((value): value is number => value !== null);

  returnText = getReturnText(rawMatches, invalidFields);

  const matches = rawMatches.map(escape);

  return {
    invalidFields,
    matches,
    returnText,
    ...(options.returnRegexes
      ? {
          validRegexes,
        }
      : {}),
  };
};
