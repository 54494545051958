import { createPinia } from 'pinia';
import { Quasar } from 'quasar';
import { createApp } from 'vue';

import App from './App.vue';
import i18n from './plugins/i18n';
import Mixpanel from './plugins/mixpanel';
import { setQuasarCssVar } from './plugins/quasar';
import query from './plugins/query';
import sentry from './plugins/sentry';
import trpc from './plugins/trpc';
import { router } from './routes';
import { taxoFieldValidationService } from './services/taxo/field-validation/index';

import './styles/tailwind.sass';
import './styles/global.sass';

// to fix
// @ts-ignore
window.process = {};

const pinia = createPinia();

Office.onReady(async (info) => {
  console.info('office is ready', info);

  const app = createApp(App);

  app
    .use(pinia)
    .use(router)
    .use(Mixpanel)
    .use(Quasar, {})
    .use(query)
    .use(i18n)
    .use(trpc, {
      loopHost: import.meta.env.VITE_LOOP_ENDPOINT,
    })
    .use(sentry, {
      router,
      tags: {
        host: info.host.toString(),
        platform: info.platform.toString(),
      },
    })
    .use(taxoFieldValidationService);

  app.mount('#app');
  setQuasarCssVar();
});
