import type {
  FieldInclude,
  TaxonomyFieldText,
} from '@grasp-gg/lib-core-taxonomies';

import { fieldColumnAlias, parametersAt, self } from './utils';

export function asDataValidation(field: TaxonomyFieldText) {
  const validation = {
    custom: {
      formula: `=AND(
        LEN(${self()})>=${parametersAt(fieldColumnAlias(field.id), 1)},
        LEN(${self()})<=${parametersAt(fieldColumnAlias(field.id), 2)},
        AND(${/* Check there is no #VALUE! (meaning invalid character) */ ''}
          ISNUMBER(${/* 1 if char is inside, #VALUE! if not */ ''}
            FIND(${/* For each character, find if it is in the allowed list */ ''}
              MID(${/* Extract all string characters */ ''}
                ${self()},
                SEQUENCE(1, LEN(${self()}), 1, 1),${/* Generates a list of character index for each char */ ''}
                1
              ),
              ${parametersAt(fieldColumnAlias(field.id), 3)}
            )
          )
        )
      )`.replace(/\s+/g, ''),
    },
  } satisfies Excel.DataValidationRule;

  if (validation.custom.formula.length > 255)
    console.error(
      `Field validation for ${field.id} will fail due to formula length. Allowed < 255 characters, got ${validation.custom.formula.length}`,
      {
        formula: validation.custom.formula,

        field,
      },
    );

  return validation;
}

export function encodeParameters(field: TaxonomyFieldText) {
  return [
    field.parameters.min,
    field.parameters.max,
    allowedCharacters(field.parameters.include),
  ];
}

function allowedCharacters(options: Partial<FieldInclude> = {}) {
  let value = '';

  value += options.digits !== false ? '0123456789' : '';
  value += options.spaces ? ' ' : '';
  value += options.uppercase !== false ? 'ABCDEFGHIJKLMNOPQRSTUVWXYZ' : '';
  value += options.lowercase !== false ? 'abcdefghijklmnopqrstuvwxyz' : '';
  value += options.characters ?? '';

  return value;
}
