import { onTable } from './setup';
import {
  columnRows,
  emptyValues,
  ensureColumnExists,
  ensureRowsCount,
} from './utils';

export const setRows = (options: {
  table: string;
  column: string;
  values: string[];
}) =>
  onTable(options.table, async ({ table }) => {
    const { column, values } = options;

    await ensureColumnExists({
      columnName: column,
      table,
    });

    const realCount = await ensureRowsCount({
      count: values.length,
      table,
    });

    const rows = await columnRows({
      name: column,
      table,
    });

    if (!rows)
      throw new Error(
        `Column '${column}' not found on table '${options.table}'`,
      );

    rows.values = [
      ...values.map((value) => [value]),
      ...emptyValues(realCount - values.length),
    ];

    await table.context.sync();
  });

export async function upsertAlias(options: {
  name: string;
  table: string;
  column: string;
}) {
  await Excel.run(async (context) => {
    const to = `=${options.table}[${options.column}]`;
    const alias = context.workbook.names.getItemOrNullObject(options.name);
    await context.workbook.context.sync();

    if (alias.isNullObject) {
      context.workbook.names.add(options.name, to);
    } else {
      alias.set({ formula: to });
    }

    await context.workbook.context.sync();
  });
}
