export function parseAddress(address: string) {
  // eslint-disable-next-line prefer-const
  let [sheet, range] = address.split('!');

  const [start, end] = range.split(':');

  if (sheet.includes(' ') || sheet.includes('-')) {
    // Sheet name is defined to `'My Sheet'`
    // we want to ignore the quotes at the beginning and end
    sheet = sheet.slice(1, -1);
  }

  return {
    sheet,
    range,
    start,
    end,
  };
}

export function toRange(context: Excel.RequestContext, address: string) {
  const { sheet, range } = parseAddress(address);
  let worksheet;

  if (!sheet) worksheet = context.workbook.worksheets.getActiveWorksheet();
  else worksheet = context.workbook.worksheets.getItem(sheet);

  return worksheet.getRange(range);
}

export function coordinatesToExcelAddress(
  x: number,
  y: number,
  [xOrign, yOrigin]: [number, number] = [0, 0],
) {
  let col = x + xOrign + 1;
  let columnLetter = '';

  while (col > 0) {
    col--;
    columnLetter = String.fromCharCode(65 + (col % 26)) + columnLetter;
    col = Math.floor(col / 26);
  }

  return columnLetter + Number(y + yOrigin + 1).toString();
}

export function excelAddressToCoordinates(range: string): [number, number] {
  const match = range.match(/^([A-Z]+)(\d+)$/);

  if (!match) {
    throw new Error('Invalid Excel range format');
  }

  const [, columnLetters, rowString] = match;

  // Convert column letters to number
  let column = 0;

  for (let i = 0; i < columnLetters.length; i++) {
    column *= 26;
    column += columnLetters.charCodeAt(i) - 65; // 'A' is 65 in ASCII
  }

  // Convert row string to number
  const row = parseInt(rowString, 10);

  return [column, row - 1];
}
