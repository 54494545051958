import { compact, uniq } from 'lodash';
import intersection from 'lodash/intersection';
import keyBy from 'lodash/keyBy';

import { validateGuidelineWithSettings } from '../../../../helpers';
import type {
  ExcludeGeoTargetingApiModel,
  NetworkGuideline,
} from '../../../guidelines';
import {
  getTargetedCountries,
  getTargetedLocations,
} from '../helpers/targeting';
import type { Targeting } from '../types';
import countryGroups from './groups';

type Guideline = NetworkGuideline<ExcludeGeoTargetingApiModel, 'facebook'>;
type AdvancedGuideline = Extract<Guideline, { __advanced?: true }>;

const countryGroupsByKey = keyBy(countryGroups, 'key');

export const isAdvancedExcludeGeoTargetingGuideline = (
  guideline: Guideline,
): guideline is AdvancedGuideline =>
  !!(guideline as AdvancedGuideline).values?.length;

const checkValidityExcludeGeoTargetingAdvanced = (
  targeting: Targeting | undefined,
  guideline: Extract<Guideline, { __advanced?: true }> | undefined,
): boolean => {
  if (!guideline) {
    return false;
  }

  const targetedCountries = compact(
    uniq([
      ...getTargetedLocations(targeting?.geo_locations, []),
      ...(targeting?.geo_locations?.country_groups
        ?.map((group) => countryGroupsByKey[group].country_codes)
        ?.flat() || []),
    ]),
  );

  // Remove locations to exclude of which country is not included
  const guidelineValues = guideline.values.filter((value) =>
    targetedCountries.includes(value.country_code),
  );

  // Remove countries excluded that are not included
  const excludedLocations = getTargetedLocations(
    targeting?.excluded_geo_locations,
    guideline.values.map(({ key }) => key),
    targetedCountries,
  );

  return validateGuidelineWithSettings(
    guideline,
    guidelineValues,
    excludedLocations,
    (guidelineValue, entityValue) => guidelineValue.key === entityValue,
    true,
  );
};

export const checkValidityExcludeGeoTargeting = (
  targeting: Targeting | undefined,
  guideline: Guideline | undefined,
): boolean => {
  if (!guideline) {
    return false;
  }

  if (isAdvancedExcludeGeoTargetingGuideline(guideline)) {
    return checkValidityExcludeGeoTargetingAdvanced(targeting, guideline);
  }

  const excludedCountries = getTargetedCountries(
    targeting?.excluded_geo_locations,
  );
  const targetedCountries = getTargetedCountries(targeting?.geo_locations);
  const targetedCountryGroups = targeting?.geo_locations?.country_groups || [];

  const targetedValues = [
    ...targetedCountries,
    ...targetedCountryGroups
      .map((group) => countryGroupsByKey[group].country_codes)
      .flat(),
  ];

  // Remove countries to exclude that are not included
  const guidelineValues = intersection(
    guideline.value?.countries || [],
    targetedValues,
  );

  // Remove countries excluded that are not included
  const countriesValues = intersection(excludedCountries, targetedValues);

  return validateGuidelineWithSettings(
    guideline,
    guidelineValues,
    countriesValues,
    (guidelineValue, entityValue) => guidelineValue === entityValue,
    true,
  );
};
