import { defineStore } from 'pinia';

import { service as sentry } from '../services/sentry';
import { useAuthStore } from './auth';

const removeTrailingSlash = (path: string) =>
  path.endsWith('/') ? path.slice(0, -1) : path;

export const useApiStore = defineStore('api', () => {
  const authStore = useAuthStore();

  const request = async <TResponse = unknown>(
    url: string,
    options?: RequestInit & { guest?: boolean },
  ) => {
    const token = !options?.guest
      ? await authStore.getAccessToken()
      : undefined;

    if (!token && !options?.guest) {
      throw new Error('Missing accessToken');
    }

    try {
      const result = await fetch(url, {
        ...options,
        headers: {
          ...options?.headers,
          ...(token ? { Authorization: `Bearer ${token}` } : {}),
        },
      });

      return await (result.json() as Promise<TResponse>);
    } catch (error) {
      sentry.captureException(error);
      console.error('Request failed', error);
    }

    return undefined;
  };

  const requestApiAccounts = <TResponse>(
    url: `/${string}`,
    options?: RequestInit & { guest?: boolean },
  ) =>
    request<TResponse>(
      `${removeTrailingSlash(import.meta.env.VITE_ACCOUNTS_ENDPOINT)}${url}`,
      options,
    );

  const requestApiAccountsGoogle = <TResponse>(
    url: `/${string}`,
    options?: RequestInit & { guest?: boolean },
  ) =>
    request<TResponse>(
      `${removeTrailingSlash(import.meta.env.VITE_ACCOUNTS_GOOGLE_ENDPOINT)}${url}`,
      options,
    );

  const requestApiAccountsFacebook = <TResponse>(
    url: `/${string}`,
    options?: RequestInit & { guest?: boolean },
  ) =>
    request<TResponse>(
      `${removeTrailingSlash(import.meta.env.VITE_ACCOUNTS_FACEBOOK_ENDPOINT)}${url}`,
      options,
    );

  const requestApiGuidelines = <TResponse>(
    url: `/${string}`,
    options?: RequestInit & { guest?: boolean },
  ) =>
    request<TResponse>(
      `${removeTrailingSlash(import.meta.env.VITE_GUIDELINES_ENDPOINT)}${url}`,
      options,
    );

  const requestApiTaxonomies = <TResponse>(
    url: `/${string}`,
    options?: RequestInit & { guest?: boolean },
  ) =>
    request<TResponse>(
      `${removeTrailingSlash(import.meta.env.VITE_TAXONOMIES_ENDPOINT)}${url}`,
      options,
    );

  return {
    request,
    requestApiAccounts,
    requestApiAccountsGoogle,
    requestApiAccountsFacebook,
    requestApiGuidelines,
    requestApiTaxonomies,
  };
});
