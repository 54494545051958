import type { TaxonomyFieldNumber } from '@grasp-gg/lib-core-taxonomies';

import { fieldColumnAlias, parametersAt } from '../validations/utils';
import { asSignedFormula, self } from './utils';

export function conditionFormula(field: TaxonomyFieldNumber): string {
  return asSignedFormula({
    signature: field.id,
    formula: `NOT(
      AND(
        ${self()}>=${parametersAt(fieldColumnAlias(field.id), 1)},
        ${self()}<=${parametersAt(fieldColumnAlias(field.id), 2)}
      )
    )`,
  });
}
