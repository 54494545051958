const TABLE_NAME = '_gg_txo_val_cache';

export function fieldColumn(fieldId: string) {
  return `${TABLE_NAME}[${fieldId}]`;
}

export function parametersAt(columnRange: string, index: number) {
  return `INDEX(${columnRange},${index})`;
}

export function self() {
  return 'INDIRECT("RC", FALSE)';
}

// Excel limit the formula length to 255 characters in custom data validation.
// In some validation, this limit is reached, so we need to alias the field
// to get a shorter parameters value access and stay under the limit.
//
// A field alias use the latest 5 characters of the field id.
// This can produce collision so an alias is generated only if necessary.
//
// Please use it only if necessary and prefer the `fieldColumn` function when it's not.
export function fieldColumnAlias(fieldId: string) {
  return `_gg_${fieldId.slice(-5)}`;
}
