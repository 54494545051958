import type { StandardField } from '../../../../fields';
import getRegexByField from '../../../get-regex-by-field';
import type { Options, ValidRegex } from '../../types';
import getRepeatOperator from '../get-repeat-operator';
import optimizeValidity from './optimize-validity';

const optimizeInvalidFixed = (
  fields: StandardField[],
  index: number,
  newRegex: string,
) => {
  if (
    fields[index].required &&
    fields[index].type === 'fixed' &&
    fields[index - 1]?.type === 'anything' &&
    fields[index + 1]?.type === 'anything' &&
    fields[index - 1].required
  ) {
    return '()';
  }

  return newRegex;
};

const getLastFieldRegex = (fields: StandardField[], index: number) =>
  `${index === fields.length - 1 ? '$' : ''}`;

/**
 * @returns [valid, currentRegexes, newRegex]
 **/
export default (
  fields: StandardField[],
  text: string,
  field: StandardField,
  index: number,
  newRegex: string,
  currentRegexes: ValidRegex[],
  options: Options,
): [boolean, ValidRegex[], string] => {
  const untilNowRegex = `^${[...currentRegexes.map(([regex]) => regex)].join(
    '',
  )}`;
  const lastFieldRegex = getLastFieldRegex(fields, index);
  const allRegex = `${untilNowRegex}(?<new>${newRegex})${lastFieldRegex}`;

  const newRequiredRegex = `${getRegexByField(
    {
      ...field,
      required: true,
    },
    fields[index + 1],
    false,
  )}`;

  // optimization: if the current regex is not present in text, return right away
  if (index < fields.length - 1 && !new RegExp(newRequiredRegex).test(text)) {
    if (!field.required) {
      return [true, currentRegexes, '()'];
    }

    return [false, currentRegexes, newRegex];
  }

  const optimzed = optimizeValidity(
    fields,
    text,
    field,
    index,
    currentRegexes,
    allRegex,
    newRegex,
  );

  if (optimzed) {
    return optimzed;
  }

  // if current is static + invalid
  // and previous one is valid
  if (field.type === 'fixed' && currentRegexes[index - 1][1]) {
    const repeatOperator = getRepeatOperator(options);
    // we will check if extra text is in between
    const newValid = new RegExp(
      `${untilNowRegex}[^${field.value[0]}]${repeatOperator}${newRegex.replace(/[?]\)(\$)?$/, ')$1')}${lastFieldRegex}`,
    ).test(text);

    if (newValid) {
      return [
        // now the current one is valid
        true,
        [
          ...currentRegexes.slice(0, -1),
          [
            currentRegexes[index - 1][0].replace(
              /\)$/,
              `[^${field.value[0]}]${repeatOperator})`,
            ),
            // and previous one is not because of the extra text
            false,
          ],
        ],
        newRegex,
      ];
    }

    return [
      false,
      currentRegexes,
      optimizeInvalidFixed(fields, index, newRegex),
    ];
  }

  return [false, currentRegexes, newRegex];
};
